const config = {
  apiGateway: {
    REGION: 'us-west-2',
    PHY_LOCATION_APIGATEWAY_URL: 'https://g0zr3rzi84.execute-api.us-west-2.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://i3j3yj6lp4.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://p7om6uvfsk.execute-api.us-west-2.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.11.2',
    HOSTNAME: 'https://locations.dev.laika.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.dev.laika.forwoodsafety.com',
    WEBSOCKET: 'wss://1wnibn8oa1.execute-api.us-west-2.amazonaws.com/dev'
  },
};

export default config;